import React from "react";
import { SectionCard } from "./sectionCard";
import "./about.css";

export const About = (props) => {
  return (
    <section id="about">
      <div className="container p-5">
        {props.data ? (
          props.data.sections.map((sec, i) => {
            return (
              <div className="row" key={"row" + i}>
                <SectionCard section={sec} />
                <div
                  className={
                    "col-xs-12 col-md-6 p-md-5 my-auto" +
                    (i % 2 === 1 ? " order-lg-first" : "")
                  }
                >
                  <img
                    src={sec.img}
                    className="img-responsive"
                    alt={sec.imgAlt ?? "Illustrative image"}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <h5 className="fs-4">Loading...</h5>
        )}
      </div>
    </section>
  );
};
