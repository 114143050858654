import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import "./contact.css";

const initialState = {
    name: "",
    email: "",
    phone: "",
    loading: false,
};
export const Contact = (props) => {
    const [{ name, email, phone, loading }, setState] = useState(initialState);
    const [sucessState, setSucessState] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };
    const clearState = () => setState({ ...initialState });

    const handleSubmit = (e) => {
        e.preventDefault();

        setState((prevState) => ({ ...prevState, loading: true }));

        emailjs.sendForm("service_vykmjsg", "template_pgwd5hp", e.target, "RVlbrfR4QHBY8hFFZ").then(
            (result) => {
                clearState();
                setSucessState(true);
            },
            (error) => {
                setState((prevState) => ({ ...prevState, loading: false }));
                console.log(error.text);
            }
        );
    };
    return (
        <>
            <section id="contact">
                <div className="container p-5">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <h2>{props.data ? props.data.title : "Loading"}</h2>
                            <p>{props.data ? props.data.paragraph : "Loading"}</p>
                            <div className="contact-item">
                                <a href="mailto:learnwithlava@gmail.com">
                                    <span>
                                        <i className="fa fa-envelope-o me-2" /> Email
                                    </span>
                                    {props.data ? props.data.email : "Loading..."}
                                </a>
                            </div>
                        </div>

                        <div className={"d-none d-md-block col-md-8 contact-info" + (sucessState ? " d-md-none" : "")}>
                            <form name="sentMessage" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="input-group">
                                        <input type="text" id="name" name="name" className="form-control" placeholder={props.data ? props.data.formFields[0] + "*" : "Loading..."} required onChange={handleChange} />
                                        <input type="email" id="email" name="email" className="form-control" placeholder={props.data ? props.data.formFields[1] + "*" : "Loading..."} required onChange={handleChange} />
                                        <input type="tel" id="phone" name="phone" className="form-control" placeholder={props.data ? props.data.formFields[2] : "Loading..."} maxLength="11" onChange={handleChange} />
                                        <button type="submit" className="btn btn-custom">
                                            {props.data && !loading ? props.data.callToAction : "Loading..."}
                                        </button>
                                    </div>
                                </div>
                                <div id="success"></div>
                            </form>
                        </div>

                        <div className={"d-block d-md-none col contact-info mt-3" + (sucessState ? " d-none" : "")}>
                            <form name="sentMessage" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group">
                                        <input type="text" id="name" name="name" className="form-control mb-3" placeholder={props.data ? props.data.formFields[0] + "*" : "Loading..."} required onChange={handleChange} />
                                        <input type="email" id="email" name="email" className="form-control mb-3" placeholder={props.data ? props.data.formFields[1] + "*" : "Loading..."} required onChange={handleChange} />
                                        <input type="phone" id="phone" name="phone" className="form-control mb-3" placeholder={props.data ? props.data.formFields[2] + "*" : "Loading..."} onChange={handleChange} />
                                        <button type="submit" className="btn btn-custom w-100">
                                            {props.data && !loading ? props.data.callToAction : "Loading..."}
                                        </button>
                                    </div>
                                </div>
                                <div id="success"></div>
                            </form>
                        </div>

                        <div className={"col-md-8 mt-3" + (!sucessState ? " d-none" : "")}>
                            <div className="toast d-block w-100" role="alert" aria-live="assertive" aria-atomic="true">
                                <div className="toast-header">
                                    <img src="/img/favicon.ico" className="rounded me-2" alt="Lava logo" />
                                    <strong className="me-auto">lava</strong>
                                    <small>Now</small>
                                </div>
                                <div className="toast-body">{props.data ? props.data.success : "Loading..."}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="footer">
                <div className="container text-center px-5">
                    <p>
                        &copy; 2024
                        <a href="https://learnwithlava.com/" className="mx-3">
                            <img src="img/logo/logo-lavaranja-hor.svg" height="20px" alt="Lava logo" />
                        </a>
                        <a className="ms-3" href="https://www.flaticon.com/free-icons/flag" title="flag icons">
                            Flag icons created by Freepik - Flaticon
                        </a>
                        .
                    </p>
                </div>
            </div>
        </>
    );
};
