import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation/navigation";
import { Header } from "./components/header/header";
import { Features } from "./components/features/features";
import { About } from "./components/about/about";
import { Team } from "./components/team/Team";
import { Contact } from "./components/contact/contact";
import EnData from "./data/data.json";
import PtData from "./data/data-pt.json";
import "./App.css";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    if (navigator.language && navigator.language.startsWith("pt"))
      setLandingPageData(PtData);
    else
      setLandingPageData(EnData);
  }, []);

  const languageHandler = (language) => {
    if (language === "pt")
      setLandingPageData(PtData);
    else
      setLandingPageData(EnData);
  };

  return (
    <div>
      <Navigation data={landingPageData.Navigation} languageHandler={languageHandler}/>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
