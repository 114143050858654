import React from "react";
import "./header.css"

export const Header = (props) => {
  return (
    <header id="header" className="pt-7">
      <div className="container p-5 align-items-center">
        <div className="row align-items-center mt-5 my-lg-5">
          <div className="col-md-6 text-md-start text-center py-6">
            <h1 className="mb-4 fw-bold">{props.data ? props.data.title : "Loading..."}</h1>
            <p className="mb-6 lead text-secondary">{props?.data ? props.data.paragraph : "Loading..."}</p>
            <div className="text-center text-md-start">
              <a className="btn me-3 btn-lg call-to-action" href="#contact" role="button">{props?.data ? props.data.callToAction : "Loading..."}</a>
            </div>
          </div>
          <div className="col-md-6 text-end mt-5 mt-lg-1">
            <img className="pt-7 pt-md-0 img-fluid" src="/img/canva/team-phone.png" alt="Group of students using their phones studying" />
          </div>
        </div>
      </div>
    </header>
  );
};
