import React from "react";
import "./navigation.css";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-expand-lg fixed-top">
      <div className="container">
        <a className="navbar-brand page-scroll" href="#page-top">
          <img src="img/logo/logo-lavaranja.svg" height="100%" alt="logo" />
          lava
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav ms-auto">
            <li>
              <a href="#features" className="page-scroll nav-link">
                {props.data ? props.data.section[0] : "Loading..."}
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll nav-link">
                {props.data ? props.data.section[1] : "Loading..."}
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll nav-link">
                {props.data ? props.data.section[2] : "Loading..."}
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll nav-link">
                {props.data ? props.data.section[3] : "Loading..."}
              </a>
            </li>
          </ul>
          <div className="d-flex ms-lg-4">
            <a
              className="btn ms-lg-3 call-to-action"
              href="#contact"
              role="button"
            >
              {props.data ? props.data.callToAction : "Loading"}
            </a>
            <div className="dropdown ms-3">
              <button
                className="btn h-100"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-globe"></i>
              </button>
              <ul className="dropdown-menu">
                <li
                  onClick={() =>
                    props.languageHandler ? props.languageHandler("pt") : null
                  }
                >
                  <p className="dropdown-item mb-0" href="#">
                    <img
                      src="img/flags/brazil.png"
                      alt="Brazilian flag"
                      className="me-3"
                    />

                    {props.data ? props.data.languages[0] : "Loading..."}
                  </p>
                </li>
                <li
                  onClick={() =>
                    props.languageHandler ? props.languageHandler("en") : null
                  }
                >
                  <p className="dropdown-item mb-0" href="#">
                    <img
                      src="img/flags/united-states.png"
                      alt="US flag"
                      className="me-3"
                    />
                    {props.data ? props.data.languages[1] : "Loading..."}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
